import React, {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
} from "react";
import axios from "axios";
import moment from "moment";

import api from "../../services/api";
import apiS3Handler from "../../services/apiS3Handler";

import { verifyPermission } from "../../services/auth";
import { onlyNumbers } from "../../services/onlyNumber";
import { Company as CompanyModel } from "../../models/entities/company";
import brazilStates from "../../services/allBrazilStates";
import paraguaiDistrict from "../../services/allParaguayDistrict";

import { User as UserModel } from "../../models/entities/user";

import Brasil from "../../assets/image/brasil.webp";
import Paraguai from "../../assets/image/paraguai.webp";

import Upload from "../../components/Upload";
import MonetaryInput from "../../components/MonetaryInput";
import MaskedInput from "antd-mask-input";

import {
  message as messageAnt,
  Divider,
  DatePicker,
  Tooltip,
  Radio,
  Spin,
} from "antd";

import {
  Modal,
  Form,
  StepperConatiner,
  Steps,
  StepIcon,
  CancelButton,
  SaveButton,
  Row,
  Col,
  Select,
  Option,
  FormUpload,
  ContainerImg,
  AddOrRemoveButton,
  Space,
  Input,
  TrashIcon,
  ContentUploadDiv,
  DashedContractDiv,
  Centralizer,
  ContentHeader,
  ContentButton,
  ButtonCountry,
  Country,
  IconCopy,
  CopyContainer,
  ButtonCopy,
} from "./styles";
import Spinner from "../../components/Spinner";

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  setCompany: Dispatch<SetStateAction<CompanyModel | null>>;
  company: CompanyModel | null;
  users: UserModel[];
}

interface IUserInfo {
  index: number | null;
  user_id: number | null;
  company_percentage: number | null;
  id: number | null;
  company_id: number | null;
  role: number | null;
}

interface Cities {
  city_id: number;
  description: string;
}

interface Departaments {
  departament_id: number;
  description: string;
}

interface Districts {
  district_id: number;
  description: string;
}

const reasonOptions = [
  { id: 1, description: "Troca de ponto" },
  { id: 2, description: "Venda de ponto" },
  { id: 3, description: "Baixo faturamento" },
  { id: 4, description: "Outros" },
  { id: 5, description: "Em montagem" },
];

const CompanyForm: React.FC<IProps> = ({
  company,
  setCompany,
  setShouldSearch,
  visible,
  setVisible,
  users,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [image, setImage] = useState<File | null>(null);
  const [contractFile, setContractFile] = useState<File | null>(null);
  const [userRows, setUserRows] = useState<IUserInfo[]>([]);
  const [showOtherReason, setShowOtherReason] = useState(false);
  const [selectedReason, setSelectedReason] = useState<number | undefined>(
    undefined
  );
  const [otherReason, setOtherReason] = useState<string | null>(null);

  const [selectCountryInput, setSelecteCountryInput] = useState("BR");

  const [districts, setDistricts] = useState<Districts[]>([]);
  const [cities, setCities] = useState<Cities[]>([]);
  const [departments, setDepartments] = useState<Departaments[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);

  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [loadingDistricts, setLoadingDistricts] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);

  const [operatingHours, setOperatingHours] = useState({
    monday: { open: "", close: "" },
    tuesday: { open: "", close: "" },
    wednesday: { open: "", close: "" },
    thursday: { open: "", close: "" },
    friday: { open: "", close: "" },
    saturday: { open: "", close: "" },
    sunday: { open: "", close: "" },
  });

  const reason = reasonOptions.find(
    (option) => option.description === company?.company_reason
  );

  const weekDays = {
    monday: "Segunda-feira",
    tuesday: "Terça-feira",
    wednesday: "Quarta-feira",
    thursday: "Quinta-feira",
    friday: "Sexta-feira",
    saturday: "Sábado",
    sunday: "Domingo",
  };

  useEffect(() => {
    const setCompanyFields = async () => {
      const responseRows = company?.companyFranchisee?.map(
        ({ user_id, company_percentage, id, company_id, role }, index) => ({
          index,
          user_id,
          company_percentage,
          id,
          company_id,
          role,
        })
      );

      responseRows && setUserRows(responseRows);
      if (!responseRows || responseRows.length === 0) {
        setUserRows([
          {
            index: 0,
            user_id: null,
            company_percentage: null,
            id: null,
            company_id: null,
            role: null,
          },
        ]);
      }

      const daysOfWeek = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];

      const initialOperatingHours: {
        monday: { open: string; close: string };
        tuesday: { open: string; close: string };
        wednesday: { open: string; close: string };
        thursday: { open: string; close: string };
        friday: { open: string; close: string };
        saturday: { open: string; close: string };
        sunday: { open: string; close: string };
      } = {
        monday: { open: "", close: "" },
        tuesday: { open: "", close: "" },
        wednesday: { open: "", close: "" },
        thursday: { open: "", close: "" },
        friday: { open: "", close: "" },
        saturday: { open: "", close: "" },
        sunday: { open: "", close: "" },
      };

      daysOfWeek.forEach((day) => {
        initialOperatingHours[day] = {
          open: company?.operating_hours?.[day]?.open || "",
          close: company?.operating_hours?.[day]?.close || "",
        };

        form.setFieldsValue({
          [`operating_hours_${day}_open`]:
            company?.operating_hours?.[day]?.open || "",
          [`operating_hours_${day}_close`]:
            company?.operating_hours?.[day]?.close || "",
        });
      });

      setOperatingHours(initialOperatingHours);

      form.setFieldsValue({
        ...company,
        contract_start: company?.contract_start
          ? moment(company?.contract_start)
          : null,
        opening_date: company?.opening_date
          ? moment(company?.opening_date)
          : null,
        contract: contractFile,
        initial_date_timbrado_py: company?.initial_date_timbrado_py
          ? moment(company?.initial_date_timbrado_py)
          : null,
      });
    };

    if (reason?.id) {
      setSelectedReason(reason?.id);
      setShowOtherReason(false);
      setOtherReason("");
    } else {
      setSelectedReason(4);
      setShowOtherReason(true);
      setOtherReason(company?.company_reason || "");
    }
    if (company?.company_status === true) {
      form.resetFields(["company_reason"]);
      setSelectedReason(undefined);
      setShowOtherReason(false);
      setOtherReason(null);
    }

    if (company) {
      setSelecteCountryInput(company?.country === "brasil" ? "BR" : "PY");
      setCompanyFields();
    } else {
      clearFields();
    }
  }, [company?.id, form]);

  const handleTimeChange = (e, day, type) => {
    const time = e.target.value;
    setOperatingHours((prevHours) => ({
      ...prevHours,
      [day]: {
        ...prevHours[day],
        [type]: time,
      },
    }));
    form.setFieldsValue({
      [`operating_hours_${day}_${type}`]: time,
    });
  };

  const copyTimeToNextDay = (currentDay) => {
    const days = Object.keys(weekDays);
    const currentIndex = days.indexOf(currentDay);
    const nextDay = days[currentIndex + 1];

    if (nextDay) {
      const currentDayHours = operatingHours[currentDay];
      setOperatingHours((prevHours) => ({
        ...prevHours,
        [nextDay]: {
          open: currentDayHours.open,
          close: currentDayHours.close,
        },
      }));
      form.setFieldsValue({
        [`operating_hours_${nextDay}_open`]: currentDayHours.open,
        [`operating_hours_${nextDay}_close`]: currentDayHours.close,
      });
    }
  };

  const handleCountryChange = (country) => {
    if (!company?.id) {
      setSelecteCountryInput(country);
    }
  };

  const handleDepartmentsFocus = async () => {
    setLoadingDepartments(true);
    try {
      const response = await api.get("/company/paraguai/departaments");
      setDepartments(response.data);
    } catch (error) {
      console.error("Error fetching departments: ", error);
    } finally {
      setLoadingDepartments(false);
    }
  };

  const handleDistrictsFocus = async () => {
    setLoadingDistricts(true);
    try {
      if (selectedDepartment) {
        const response = await api.get(
          `/company/paraguai/district?departament_id=${selectedDepartment}`
        );
        setDistricts(response.data);
      } else {
        console.error("No department selected");
      }
    } catch (error) {
      console.error("Error fetching districts: ", error);
    } finally {
      setLoadingDistricts(false);
    }
  };

  const handleCityFocus = async () => {
    setLoadingCities(true);
    try {
      if (selectedDepartment && selectedDistrict) {
        const response = await api.get(
          `/company/paraguai/cities?departament_id=${selectedDepartment}&district_id=${selectedDistrict}`
        );
        setCities(response.data);
      } else {
        console.error("No department or district selected");
      }
    } catch (error) {
      console.error("Error fetching cities: ", error);
    } finally {
      setLoadingCities(false);
    }
  };

  const handleDepartmentChange = (value, option) => {
    const description = option ? option.children : "";
    console.log("Selected Department:", value, description);
    setSelectedDepartment(value);
    setSelectedDistrict(null);
    setDistricts([]);
    setCities([]);

    setCompany((oldValues) => {
      if (oldValues) {
        return {
          ...oldValues,
          departament_id_py: value,
          description_departament_py: description,
        };
      }
      return null;
    });

    form.setFieldsValue({
      description_departament_py: description,
      departament_id_py: value,
      district_id_py: null,
      distrito_py: null,
      city_id: null,
      city: null,
    });
  };

  const handleDistrictChange = (value, option) => {
    const description = option ? option.children : "";
    console.log("Selected District:", value, description);
    setSelectedDistrict(value);
    setCities([]);

    setCompany((oldValues) => {
      if (oldValues) {
        return {
          ...oldValues,
          district_id_py: value,
          distrito_py: description,
        };
      }
      return null;
    });

    form.setFieldsValue({
      distrito_py: description,
      district_id_py: value,
      city_id: null,
      city: null,
    });
  };

  const handleCityChange = (value, option) => {
    const description = option ? option.children : "";
    console.log("Selected City:", value, description);

    setCompany((oldValues) => {
      if (oldValues) {
        return {
          ...oldValues,
          city_id: value,
          city: description,
        };
      }
      return null;
    });

    form.setFieldsValue({
      city: description,
      city_id: value,
    });
  };

  const handleState = (name: string, value) => {
    let valueFormat = value;
    if (
      typeof valueFormat === "string" &&
      name !== "emitente_uf" &&
      name !== "state_registration"
    ) {
      valueFormat = valueFormat.toLowerCase();
    }

    if (name === "company_status" && valueFormat === true) {
      form.resetFields(["company_reason"]);
      setSelectedReason(undefined);
      setShowOtherReason(false);
      setOtherReason(null);
    }
    setCompany((oldValues) => ({ ...oldValues, [name]: valueFormat }));
  };

  const clearFields = async () => {
    setCurrentPage(1);
    setSelectedReason(1);
    setOtherReason("");
    setDepartments([]);
    setDistricts([]);
    setCities([]);
    setCompany(null);
    setSelectedDepartment(null);
    setSelectedDistrict(null);
    setContractFile(null);
    setImage(null);
    setUserRows([
      {
        index: 0,
        user_id: null,
        company_percentage: null,
        id: null,
        company_id: null,
        role: null,
      },
    ]);
    await form.resetFields();
  };

  const payloadStringTrim = (payload) => {
    let trimPayload = payload;
    for (const objProp in trimPayload) {
      if (typeof trimPayload[objProp] === "string") {
        trimPayload[objProp] = trimPayload[objProp].trim();
      }
    }
    return trimPayload;
  };

  const onFinish = async () => {
    await form.validateFields();

    Modal.confirm({
      title: company?.id ? "Edição de empresa" : "Cadastro de empresa",
      content: `Deseja continuar e ${
        company?.id ? "editar" : "cadastrar"
      } esta empresa?`,
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        try {
          setLoading(true);

          const method = company?.id ? "put" : "post";
          const url = company?.id ? `/company/${company?.id}` : "/company";
          const countryMapping = {
            BR: "brasil",
            PY: "paraguai",
          };

          const finalOperatingHours = {};
          Object.keys(operatingHours).forEach((day) => {
            finalOperatingHours[day] = {
              open:
                operatingHours[day].open ||
                company?.operating_hours?.[day]?.open,
              close:
                operatingHours[day].close ||
                company?.operating_hours?.[day]?.close,
            };
          });

          let payload: any = {
            ...company,
            companyFranchisee:
              userRows[0].user_id !== null
                ? userRows?.map((user) => ({
                    id: user?.id,
                    company_percentage: Number(user?.company_percentage),
                    user_id: Number(user?.user_id),
                    company_id: company?.id,
                    role: user?.role,
                  }))
                : {},
            country: countryMapping[selectCountryInput],
            departament_id_py: selectedDepartment,
            description_departament_py: form.getFieldValue(
              "description_departament_py"
            ),
            district_id_py: selectedDistrict,
            distrito_py: form.getFieldValue("distrito_py"),
            city: form.getFieldValue("city"),
            population:
              form.getFieldValue("population") === ""
                ? null
                : form.getFieldValue("population"),
            operating_hours: finalOperatingHours,
          };
          if (userRows[0].user_id === null) {
            delete payload.companyFranchisee;
          }

          if (selectCountryInput === "PY") {
            payload.ruc = payload.ruc?.toString();
            delete payload.cnpj;
            delete payload.emitente_incentivo_fiscal;
            delete payload.emitente_isento;
          }

          payload.whatsapp_number = payload.whatsapp_number || "";
          payload = payloadStringTrim(payload);
          payload.city = payload.city?.toLowerCase()?.trim();

          if (payload.company_status === true) {
            payload.company_reason = null;
          } else if (selectedReason === 4 && otherReason) {
            payload.company_reason = otherReason;
          } else {
            const reason = reasonOptions.find(
              (option) => option.id === selectedReason
            );
            payload.company_reason = reason?.description;
          }

          if (payload.initial_date_timbrado_py) {
            payload.initial_date_timbrado_py = moment(
              payload.initial_date_timbrado_py
            ).format("YYYY-MM-DDTHH:mm:ss");
          }

          if (contractFile) {
            if (company?.contract_key) {
              await apiS3Handler.delete(
                `/s3-upload/upload-autheticator/${company.contract_key}`
              );
            }
            const contractUpload = new FormData();
            contractUpload.append("file", contractFile as File);

            const {
              data: { location, key },
            } = await apiS3Handler.post(
              "/s3-upload/upload/upload-autheticator",
              contractUpload
            );

            payload.contract = location;
            payload.contract_key = key;

            setContractFile(null);
          }
          if (image) {
            if (company?.image_key) {
              await apiS3Handler.delete(
                `/s3-upload/upload-autheticator/${company.image_key}`
              );
            }
            const imageToUpload = new FormData();
            imageToUpload.append("file", image as File);
            const {
              data: { location, key },
            } = await apiS3Handler.post(
              "/s3-upload/upload/upload-autheticator",
              imageToUpload
            );

            payload.image = location;
            payload.image_key = key;
            setImage(null);
          }
          delete payload.infoUser;
          delete payload.key;

          await api[method](url, payload);
          {
            company?.id
              ? messageAnt.success("Edição da empresa salva com sucesso")
              : messageAnt.success("Cadastro da empresa salvo com sucesso");
          }
          console.log(userRows);
          clearFields();
          setShouldSearch(true);
          setVisible(false);
        } catch (error: any) {
          messageAnt.error(
            error?.response?.data?.message || "Falha ao salvar empresa"
          );
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const onCancel = () => {
    Modal.confirm({
      title: "Deseja realmente cancelar?",
      content: "As informações preenchidas serão descartadas.",
      okText: "Sim",
      centered: true,
      okType: "primary",
      cancelText: "Não",
      width: 500,
      async onOk() {
        clearFields();
        setVisible(false);
      },
    });
  };

  const handleChangeCep = async (cep) => {
    const _cep = onlyNumbers(cep)?.toString();
    if (_cep?.length === 8) {
      await form.setFieldsValue({
        state_registration: "",
        city: "",
        district: "",
        address: "",
        complement: "",
        number: "",
      });
      const { data } = await axios({
        url: `https://viacep.com.br/ws/${_cep}/json/`,
        method: "GET",
      });

      setCompany((oldValues) => ({
        ...oldValues,
        state_registration: data?.uf,
        city: data?.localidade,
        district: data?.bairro,
        address: data?.logradouro,
        complement: data?.complemento,
        number: data?.numero,
      }));

      await form.setFieldsValue({
        state_registration: data?.uf,
        city: data?.localidade,
        district: data?.bairro,
        address: data?.logradouro,
        complement: data?.complemento,
        number: data?.numero,
      });
    }
  };

  const regimesTributario = [
    { id: 1, description: "Simples Nacional" },
    {
      id: 2,
      description: "Simples Nacional - excesso de sublimite de receita bruta",
    },
    { id: 3, description: "Regime Normal" },
  ];

  const regimesTributarioParaguai = [
    { id: 1, description: "Regime de Turismo" },
    { id: 2, description: "Importador" },
    { id: 3, description: "Exportador" },
    { id: 4, description: "Maquila" },
    { id: 5, description: "Lei Nº 60/90" },
    { id: 6, description: "Regime do Pequeno Produtor" },
    { id: 7, description: "Regime do Médio Produtor" },
    { id: 8, description: "Regime Contábil" },
  ];

  const handleUserChange = (index, key, value) => {
    const updatedRows = [...userRows];

    if (updatedRows[index]) {
      updatedRows[index][key] =
        key === "company_percentage" ? Number(value) : value;
      setUserRows(updatedRows);
    } else {
      messageAnt.error(
        `Houve problema na linha ${index} da seleção de franqueados / sócios.`
      );
    }
  };

  const handleAddRow = () => {
    setUserRows([
      ...userRows,
      {
        index: userRows.length,
        user_id: null,
        company_percentage: null,
        id: null,
        company_id: null,
        role: null,
      },
    ]);
  };

  const handleRemoveRow = async (indexUser: any) => {
    setUserRows((oldValues) => {
      const response = oldValues.filter((_, index) => {
        return index !== indexUser;
      });
      return response;
    });
  };

  const handleNextStep = async () => {
    try {
      await form.validateFields();
      const reduceRows = userRows.reduce(
        (acc, row) => acc + (row.company_percentage || 0),
        0
      );

      if (userRows.length > 1 || userRows[0].user_id !== null) {
        const selectedUserIds = {};

        for (const row of userRows) {
          if (row.user_id !== null && selectedUserIds[row.user_id]) {
            return messageAnt.error(`Um ou mais usuários com o mesmo nome.`);
          }
          if (row.user_id !== null) {
            selectedUserIds[row.user_id] = true;
          }
          if (row.user_id === null && currentPage === 4) {
            return messageAnt.error(
              `Por favor, selecione o ID do usuário para todas as linhas.`
            );
          }
          if (row.company_percentage === null && currentPage === 4) {
            return messageAnt.error(
              `Por favor, selecione a porcentagem do usuário para todas as linhas.`
            );
          }
        }
      }

      if (
        currentPage === 4 &&
        userRows[0].user_id !== null &&
        reduceRows !== 100
      ) {
        return messageAnt.error("A soma das porcentagens deve ser igual a 100");
      }

      if (currentPage === 1 && company && !("company_status" in company)) {
        return messageAnt.error("Selecione se a loja está ativa ou inativa");
      }

      if (currentPage === 5) {
        const _cep =
          company && selectCountryInput === "BR"
            ? onlyNumbers(company?.cep)
            : "";
        if (_cep && _cep.toString()?.length < 8) {
          return messageAnt.error("O cep deve ter 8 caracteres");
        }
        setCompany((oldValues) => ({
          ...oldValues,
          ...form.getFieldsValue(),
        }));
        if (selectCountryInput === "PY" && currentPage === 5) {
          setCurrentPage((prev) => prev + 1);
        } else {
          onFinish();
        }
      } else if (currentPage === 6) {
        onFinish();
      } else {
        setCurrentPage((prev) => prev + 1);
      }
    } catch (error: any) {
      messageAnt.warning(
        error.response?.data?.message || "Preencha os campos obrigatórios."
      );
    }
  };

  const handleContractRemove = async () => {
    try {
      if (company?.contract_key) {
        await apiS3Handler.delete(
          `/s3-upload/upload-autheticator/${company.contract_key}`
        );
      }
      setContractFile(null);
      setCompany((oldValues) => ({
        ...oldValues,
        contract: null,
        contract_key: null,
      }));
      form.setFieldsValue({ contract: null });
    } catch (error) {
      messageAnt.error("Erro ao remover o contrato.");
    }
  };

  const downloadPdfContractFile = async () => {
    try {
      const { data: pdfContent } = await apiS3Handler.get(
        `/s3-upload/find/upload-autheticator/${company?.contract_key}`
      );

      const link = document.createElement("a");
      link.href = `data:${pdfContent.contentType};base64,${pdfContent.base64}`;
      link.download = `${company?.contract_start}.pdf`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      messageAnt.error("Erro ao gerar o arquivo");
    }
  };

  const validateIfoodLink = (url) => {
    const ifoodBaseUrl = "https://www.ifood.com.br/";
    return url.startsWith(ifoodBaseUrl);
  };

  return (
    <Modal
      title={
        <ContentHeader>
          {company?.id ? "Editar empresa" : "Nova empresa"}

          <ContentButton>
            <ButtonCountry
              disabled={company?.country === "PY" || currentPage !== 1}
              selected={selectCountryInput === "BR"}
              onClick={() => handleCountryChange("BR")}
            >
              {useMemo(
                () => (
                  <Country src={Brasil}></Country>
                ),
                []
              )}{" "}
              🇧🇷
            </ButtonCountry>
            <ButtonCountry
              disabled={company?.country === "BR" || currentPage !== 1}
              selected={selectCountryInput === "PY"}
              onClick={() => handleCountryChange("PY")}
            >
              {useMemo(
                () => (
                  <Country src={Paraguai}></Country>
                ),
                []
              )}{" "}
              🇵🇾
            </ButtonCountry>
          </ContentButton>
        </ContentHeader>
      }
      centered
      closable={false}
      visible={visible}
      confirmLoading={loading}
      width={1200}
      destroyOnClose
      footer={[
        <CancelButton
          onClick={() => {
            onCancel();
          }}
        >
          {company?.id ? "Cancelar edição" : "Cancelar cadastro"}
        </CancelButton>,
        <>
          {currentPage > 1 && (
            <SaveButton
              onClick={() => setCurrentPage((prev) => prev - 1)}
              style={{ backgroundColor: "var(--gray-150)" }}
            >
              {company?.id ? "Voltar edição" : "Voltar cadastro"}
            </SaveButton>
          )}
        </>,
        <>
          <SaveButton onClick={handleNextStep}>
            {selectCountryInput === "PY"
              ? currentPage === 6
                ? company?.id
                  ? "Confirmar edição"
                  : "Confirmar cadastro"
                : company?.id
                ? "Avançar edição"
                : "Avançar cadastro"
              : currentPage === 5
              ? company?.id
                ? "Confirmar edição"
                : "Confirmar cadastro"
              : company?.id
              ? "Avançar edição"
              : "Avançar cadastro"}
          </SaveButton>
        </>,
      ]}
    >
      {loading ? (
        <Centralizer>
          <Spinner />{" "}
        </Centralizer>
      ) : (
        <Form layout="vertical" initialValues={{ remember: false }} form={form}>
          <StepperConatiner>
            <label>
              Progresso do cadastro:{" "}
              <span>
                {currentPage} de {selectCountryInput === "BR" ? 5 : 6}
              </span>
            </label>

            <Steps currentPage={currentPage}>
              <StepIcon className="step-icon1" />
              <div className="step-line1" />
              <StepIcon className="step-icon2" />
              <div className="step-line2" />
              <StepIcon className="step-icon3" />
              <div className="step-line3" />
              <StepIcon className="step-icon4" />
              <div className="step-line4" />
              <StepIcon className="step-icon5" />
              {selectCountryInput === "PY" && (
                <>
                  <div className="step-line5" />
                  <StepIcon className="step-icon6" />
                </>
              )}
            </Steps>
          </StepperConatiner>

          {currentPage === 1 && (
            <div>
              <Divider orientation="left">Emitente</Divider>
              {selectCountryInput === "BR" ? (
                <>
                  <Row gutter={8}>
                    <Col sm={6}>
                      <Form.Item
                        label="* Status da loja"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Campo obrigatório",
                          },
                        ]}
                      >
                        <Radio.Group
                          name="company_status"
                          onChange={({ target: { value } }) =>
                            handleState("company_status", value)
                          }
                          value={company?.company_status}
                        >
                          <Radio value={true}>Ativa</Radio>
                          <Radio value={false}>Inativa</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    {company?.company_status === false && (
                      <>
                        <Col sm={6}>
                          <Form.Item
                            label="Justificativa da inatividade"
                            rules={[
                              {
                                required:
                                  company?.company_status === false
                                    ? true
                                    : false,
                                message: "Campo obrigatório",
                              },
                            ]}
                          >
                            <Select
                              value={selectedReason}
                              placeholder="Justifique"
                              onChange={(value) => {
                                setSelectedReason(value as number);
                                setShowOtherReason(value === 4);
                              }}
                            >
                              {reasonOptions.map((option) => (
                                <Select.Option
                                  key={option.id}
                                  value={option.id}
                                >
                                  {option.description}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        {showOtherReason && selectedReason === 4 && (
                          <Col sm={6}>
                            <Form.Item
                              label="Motivo"
                              rules={[
                                {
                                  required: showOtherReason,
                                  message: "Campo obrigatório",
                                },
                              ]}
                            >
                              <Input
                                value={otherReason || ""}
                                onChange={({ target: { value } }) =>
                                  setOtherReason(value)
                                }
                              />
                            </Form.Item>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                  <Row gutter={4}>
                    <Col sm={6}>
                      <Form.Item
                        label="Nome Loja"
                        name="company_name"
                        rules={[
                          {
                            required: true,
                            message: "Nome Loja é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          disabled={
                            !verifyPermission(
                              "company.edit_contract_information"
                            )
                          }
                          type="text"
                          name="company_name"
                          maxLength={50}
                          placeholder="Digite aqui o nome da loja"
                          onChange={({ target: { value } }) =>
                            handleState("company_name", value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="CNPJ"
                        name="cnpj"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "CNPJ é obrigatório",
                          },
                          {
                            min: 14,
                            message: "CNPJ deve ter no minimo 14 caracteres",
                          },
                        ]}
                      >
                        <MaskedInput
                          disabled={
                            !verifyPermission(
                              "company.edit_contract_information"
                            )
                          }
                          name="cnpj"
                          mask="11.111.111/1111-11"
                          placeholder="99.999.999/9999-99"
                          onChange={({ target: { value } }) =>
                            handleState("cnpj", onlyNumbers(value)?.toString())
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={2}>
                      <Form.Item
                        label="UF"
                        name="emitente_uf"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "UF é obrigatório",
                          },
                        ]}
                      >
                        <Select
                          disabled={
                            !verifyPermission(
                              "company.edit_contract_information"
                            )
                          }
                          placeholder="UF"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(uf) => handleState("emitente_uf", uf)}
                        >
                          {brazilStates.map((state) => (
                            <Select.Option value={state} key={state}>
                              {state}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col sm={3}>
                      <Form.Item
                        label="Incentivo Fiscal"
                        name="emitente_incentivo_fiscal"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Incentivo Fiscal é obrigatório",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Incentivo Fiscal"
                          onChange={(value) =>
                            handleState("emitente_incentivo_fiscal", value)
                          }
                        >
                          <Option value={true}>Sim</Option>
                          <Option value={false}>Não</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="Razão Social"
                        name="emitente_razao_social"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Razão Social é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="emitente_razao_social"
                          maxLength={100}
                          placeholder="Digite aqui a razão social"
                          onChange={({ target: { value } }) =>
                            handleState("emitente_razao_social", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={4}>
                    <Col sm={6}>
                      <Form.Item
                        label="Nome Fantasia"
                        name="emitente_nome_fantasia"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Nome Fantasia é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="emitente_nome_fantasia"
                          maxLength={50}
                          placeholder="Nome Fantasia"
                          onChange={({ target: { value } }) =>
                            handleState("emitente_nome_fantasia", value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="Inscrição Estadual"
                        name="emitente_inscricao_estadual"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Inscrição Estadual é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="emitente_inscricao_estadual"
                          placeholder="Inscrição Estadual"
                          onChange={({ target: { value } }) =>
                            handleState("emitente_inscricao_estadual", value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <>
                      <Col sm={2}>
                        <Form.Item
                          label="Isento"
                          name="emitente_isento"
                          rules={[
                            {
                              required: selectedReason === 5 ? false : true,
                              message: "Isento é obrigatório",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Isento"
                            onChange={(value) =>
                              handleState("emitente_isento", value)
                            }
                          >
                            <Option value={true}>Sim</Option>
                            <Option value={false}>Não</Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col sm={9}>
                        <Form.Item
                          label="Regime Tributario"
                          name="emitente_regime_tributario"
                          rules={[
                            {
                              required: selectedReason === 5 ? false : true,
                              message: "Regime Tributario é obrigatório",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Regime Tributario"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) =>
                              handleState("emitente_regime_tributario", value)
                            }
                          >
                            {regimesTributario.map((regimeTributario) => (
                              <Select.Option
                                value={regimeTributario.id}
                                key={regimeTributario.id}
                              >
                                {regimeTributario.description}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  </Row>

                  <Row gutter={4}>
                    <Col sm={6}>
                      <Form.Item
                        label="Inscrição Municipal"
                        name="emitente_inscricao_municipal"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Inscrição Municipal é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="emitente_inscricao_municipal"
                          placeholder="Inscrição Municipal"
                          onChange={({ target: { value } }) =>
                            handleState("emitente_inscricao_municipal", value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <>
                      <Col sm={6}>
                        <Form.Item
                          label="CNAE"
                          name="emitente_cnae"
                          rules={[
                            {
                              required: selectedReason === 5 ? false : true,
                              message: "CNAE é obrigatório",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            name="emitente_cnae"
                            maxLength={20}
                            placeholder="CNAE"
                            onChange={({ target: { value } }) =>
                              handleState("emitente_cnae", value)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={11}>
                        <Form.Item
                          label="Discrimina Impostos"
                          name="discrimina_impostos"
                          rules={[
                            {
                              required: selectedReason === 5 ? false : true,
                              message: "Campo obrigatório",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Discrimina Impostos"
                            onChange={(value) =>
                              handleState("discrimina_impostos", value)
                            }
                          >
                            <Option value={true}>Sim</Option>
                            <Option value={false}>Não</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  </Row>
                </>
              ) : (
                //---------------------------------------------------------------------- Paraguai
                <>
                  <Row gutter={8}>
                    <Col sm={6}>
                      <Form.Item
                        label="* Status da loja"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Campo obrigatório",
                          },
                        ]}
                      >
                        <Radio.Group
                          name="company_status"
                          onChange={({ target: { value } }) =>
                            handleState("company_status", value)
                          }
                          value={company?.company_status}
                        >
                          <Radio value={true}>Ativa</Radio>
                          <Radio value={false}>Inativa</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    {company?.company_status === false && (
                      <>
                        <Col sm={6}>
                          <Form.Item
                            label="Justificativa da inatividade"
                            rules={[
                              {
                                required:
                                  company?.company_status === false
                                    ? true
                                    : false,
                                message: "Campo obrigatório",
                              },
                            ]}
                          >
                            <Select
                              value={selectedReason}
                              placeholder="Justifique"
                              onChange={(value) => {
                                setSelectedReason(value as number);
                                setShowOtherReason(value === 4);
                              }}
                            >
                              {reasonOptions.map((option) => (
                                <Select.Option
                                  key={option.id}
                                  value={option.id}
                                >
                                  {option.description}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        {showOtherReason && selectedReason === 4 && (
                          <Col sm={6}>
                            <Form.Item
                              label="Motivo"
                              rules={[
                                {
                                  required: showOtherReason,
                                  message: "Campo obrigatório",
                                },
                              ]}
                            >
                              <Input
                                value={otherReason || ""}
                                onChange={({ target: { value } }) =>
                                  setOtherReason(value)
                                }
                              />
                            </Form.Item>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                  <Row gutter={4}>
                    <Col sm={6}>
                      <Form.Item
                        label="Nome Loja"
                        name="company_name"
                        rules={[
                          {
                            required: true,
                            message: "Nome Loja é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          disabled={
                            !verifyPermission(
                              "company.edit_contract_information"
                            )
                          }
                          type="text"
                          name="company_name"
                          maxLength={50}
                          placeholder="Digite aqui o nome da loja"
                          onChange={({ target: { value } }) =>
                            handleState("company_name", value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="RUC"
                        name="ruc"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "RUC é obrigatório",
                          },
                        ]}
                      >
                        <MaskedInput
                          name="ruc"
                          mask="1111111-1"
                          placeholder="11111111-1"
                          onChange={({ target: { value } }) =>
                            handleState("ruc", onlyNumbers(value)?.toString())
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="Razão Social"
                        name="emitente_razao_social"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Razão Social é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="emitente_razao_social"
                          maxLength={100}
                          placeholder="Digite aqui a razão social"
                          onChange={({ target: { value } }) =>
                            handleState("emitente_razao_social", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <Form.Item
                        label="Nome Fantasia"
                        name="emitente_nome_fantasia"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Nome Fantasia é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="emitente_nome_fantasia"
                          maxLength={50}
                          placeholder="Nome Fantasia"
                          onChange={({ target: { value } }) =>
                            handleState("emitente_nome_fantasia", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={4}>
                    <Col sm={6}>
                      <Form.Item
                        label="CNAE"
                        name="emitente_cnae"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message:
                              "Código de atividade econômica é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          name="emitente_cnae"
                          maxLength={50}
                          placeholder="CNAE"
                          onChange={({ target: { value } }) =>
                            handleState("emitente_cnae", value)
                          }
                          onKeyDown={(e) => {
                            if (
                              e.key === "." ||
                              e.key === "," ||
                              e.key === "+" ||
                              e.key === "-" ||
                              e.key === "e" ||
                              e.key === "E"
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <Form.Item
                        label="Descrição da Atividade Econômica"
                        name="description_cnae"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message:
                              "Descrição da atividade econômica é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="description_cnae"
                          placeholder="Descrição da Atividade Econômica"
                          onChange={({ target: { value } }) =>
                            handleState("description_cnae", value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="Regime Tributario"
                        name="emitente_regime_tributario"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Regime Tributario é obrigatório",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Regime Tributario"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) =>
                            handleState("emitente_regime_tributario", value)
                          }
                        >
                          {regimesTributarioParaguai.map((regimeTributario) => (
                            <Select.Option
                              value={regimeTributario.id}
                              key={regimeTributario.id}
                            >
                              {regimeTributario.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          )}

          {currentPage === 2 && (
            <>
              <Divider orientation="left">Contato</Divider>
              <Row gutter={4}>
                <Row gutter={8} style={{ marginBottom: "1rem" }}>
                  {company?.image && (
                    <Col>
                      <ContainerImg>
                        <img src={company?.image} alt="" />
                      </ContainerImg>
                    </Col>
                  )}
                  <Col sm={8}>
                    <FormUpload label="Imagem da loja">
                      <Upload file={image} setFile={setImage} />
                    </FormUpload>
                  </Col>
                </Row>
                <Col sm={8}>
                  <Form.Item
                    label="Telefone"
                    name="telefone"
                    rules={[
                      { required: false, message: "Telefone é obrigatório" },
                    ]}
                  >
                    <MaskedInput
                      disabled={
                        !verifyPermission("company.edit_contract_information")
                      }
                      name="telefone"
                      mask="(11) 11111-1111"
                      placeholder="(99) 99999-9999"
                      onChange={({ target: { value } }) => {
                        const formattedValue =
                          onlyNumbers(value)?.toString() || "";
                        handleState("telefone", formattedValue);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col sm={8}>
                  <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[
                      {
                        required:
                          company?.enviar_email_destinatario === true
                            ? true
                            : false,
                        message: "E-mail é obrigatório",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      onChange={({ target: { value } }) =>
                        handleState("email", value)
                      }
                    />
                  </Form.Item>
                </Col>

                <Col sm={8}>
                  <Form.Item
                    label="Enviar e-mail ao destinatário"
                    name="enviar_email_destinatario"
                    rules={[
                      {
                        required: selectedReason === 5 ? false : true,
                        message: "Campo obrigatório",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Enviar e-mail"
                      onChange={(value) =>
                        handleState("enviar_email_destinatario", value)
                      }
                    >
                      <Option value={true}>Sim</Option>
                      <Option value={false}>Não</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {currentPage === 3 && (
            <>
              <Divider orientation="left">Atendimento</Divider>
              <Row gutter={8}>
                <Col sm={12}>
                  <Form.Item label="Horário" name="description_store_time">
                    <Input
                      type="string"
                      name="description_store_time"
                      placeholder="Digite o horário de funcionamento"
                      maxLength={70}
                      onChange={({ target: { value } }) =>
                        handleState("description_store_time", value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col sm={12}>
                  <Form.Item
                    label="Horário de Delivery"
                    name="description_delivery_time"
                  >
                    <Input
                      type="string"
                      name="description_delivery_time"
                      maxLength={70}
                      placeholder="Digite o horário de delivery"
                      onChange={({ target: { value } }) =>
                        handleState("description_delivery_time", value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col sm={12}>
                  <Form.Item
                    label="Plataformas de Delivery"
                    name="description_delivery_plataform"
                  >
                    <Input
                      type="string"
                      maxLength={50}
                      name="description_delivery_plataform"
                      placeholder="Digite as plataformas de delivery"
                      onChange={({ target: { value } }) =>
                        handleState("description_delivery_plataform", value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col sm={12}>
                  <Form.Item
                    label="Whatsapp de Atendimento"
                    name="whatsapp_number"
                  >
                    <MaskedInput
                      name="whatsapp_number"
                      mask="(11) 11111-1111"
                      placeholder="(99) 99999-9999"
                      onChange={({ target: { value } }) =>
                        handleState("whatsapp_number", onlyNumbers(value))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <p className="warning">
                  * Essas informações serão exibidas no The Best Açaí Site.
                </p>
              </Row>
              <Divider orientation="left">Informações Club The Best</Divider>
              <Row gutter={8}>
                <Col sm={6}>
                  <Form.Item label="Data de Inauguração" name="opening_date">
                    <DatePicker
                      placeholder="Data de Inauguração"
                      name="opening_date"
                      format="DD/MM/YYYY"
                      onChange={(value) => handleState("opening_date", value)}
                      allowClear={true}
                      inputReadOnly={true}
                    />
                  </Form.Item>
                </Col>

                <Col sm={12}>
                  <Form.Item
                    label="Ifood URL"
                    name="ifood_url"
                    rules={[
                      {
                        message: "Por favor, insira a URL do Ifood.",
                      },
                      {
                        validator: (_, value) =>
                          value && !validateIfoodLink(value)
                            ? Promise.reject(
                                new Error(
                                  "Link inválido do iFood. Deve começar com https://www.ifood.com.br/"
                                )
                              )
                            : Promise.resolve(),
                      },
                    ]}
                  >
                    <Input
                      onChange={({ target: { value } }) =>
                        handleState("ifood_url", value)
                      }
                      placeholder="URL do Ifood"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {Object.entries(weekDays).map(([day, label], index) => (
                <Row gutter={8} key={day}>
                  <Col sm={6}>
                    <Form.Item
                      label={`Abertura (${label})`}
                      name={`operating_hours_${day}_open`}
                      rules={[
                        {
                          message: `Por favor, insira o horário de abertura de ${label}.`,
                        },
                        {
                          validator: (rule, value) => {
                            if (selectedReason === 5) {
                              return Promise.resolve();
                            }
                            if (
                              !/^(2[0-3]|[01]?[0-9]):[0-5][0-9]$/.test(value)
                            ) {
                              return Promise.reject(
                                new Error(
                                  "Insira um horário válido entre 00:00 e 23:59."
                                )
                              );
                            }
                            const closingTime = form.getFieldValue(
                              `operating_hours_${day}_close`
                            );
                            if (closingTime && value && value > closingTime) {
                              return Promise.reject(
                                new Error(
                                  `O horário de abertura não pode ser maior que o de fechamento.`
                                )
                              );
                            }
                            if (closingTime && value && value >= closingTime) {
                              return Promise.reject(
                                new Error(
                                  `O horário de abertura não pode ser igual o de fechamento.`
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <MaskedInput
                        mask="11:11"
                        placeholder="00:00"
                        onChange={(e) => handleTimeChange(e, day, "open")}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={6}>
                    <Form.Item
                      label={`Fechamento (${label})`}
                      name={`operating_hours_${day}_close`}
                      rules={[
                        {
                          message: `Por favor, insira o horário de fechamento de ${label}.`,
                        },
                        {
                          validator: (rule, value) => {
                            if (selectedReason === 5) {
                              return Promise.resolve();
                            }
                            if (
                              !/^(2[0-3]|[01]?[0-9]):[0-5][0-9]$/.test(value)
                            ) {
                              return Promise.reject(
                                new Error(
                                  "Insira um horário válido entre 00:00 e 23:59."
                                )
                              );
                            }
                            const openingTime = form.getFieldValue(
                              `operating_hours_${day}_open`
                            );
                            if (openingTime && value && value < openingTime) {
                              return Promise.reject(
                                new Error(
                                  `O horário de fechamento não pode ser menor que o de abertura.`
                                )
                              );
                            }
                            if (openingTime && value && value <= openingTime) {
                              return Promise.reject(
                                new Error(
                                  `O horário de fechamento não pode ser igual o de abertura.`
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <MaskedInput
                        mask="11:11"
                        placeholder="00:00"
                        onChange={(e) => handleTimeChange(e, day, "close")}
                      />
                    </Form.Item>
                  </Col>
                  {index < Object.entries(weekDays).length - 1 && (
                    <CopyContainer>
                      <Tooltip title={`Copiar horário para o próximo dia`}>
                        <ButtonCopy
                          onClick={() => copyTimeToNextDay(day)}
                          icon={<IconCopy />}
                        ></ButtonCopy>
                      </Tooltip>
                    </CopyContainer>
                  )}
                </Row>
              ))}
            </>
          )}

          {currentPage === 4 && (
            <>
              <Divider orientation="left">Contrato</Divider>
              <Row gutter={4}>
                <Col sm={6}>
                  <Form.Item
                    label="Área de ocupação"
                    name="occupation_area"
                    rules={[
                      {
                        required: selectedReason === 5 ? false : true,
                        message: "Área de ocupação é obrigatório",
                      },
                    ]}
                  >
                    <Input
                      disabled={
                        !verifyPermission("company.edit_contract_information")
                      }
                      type="text"
                      name="occupation_area"
                      placeholder="Área de ocupação"
                      maxLength={50}
                      onChange={({ target: { value } }) =>
                        handleState("occupation_area", value)
                      }
                      suffix={"KM"}
                    />
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <Form.Item
                    label="Taxa"
                    name="franchisee_fee"
                    initialValue={0}
                    rules={[{ required: false, message: "Taxa é obrigatório" }]}
                  >
                    <MonetaryInput
                      disabled={
                        !verifyPermission("company.edit_contract_information")
                      }
                      value={company?.franchisee_fee}
                      onChange={(value) => handleState("franchisee_fee", value)}
                    />
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <Form.Item
                    label="Royalts"
                    name="royalts"
                    initialValue={0}
                    rules={[
                      { required: false, message: "Royalts é obrigatório" },
                    ]}
                  >
                    <MonetaryInput
                      disabled={
                        !verifyPermission("company.edit_contract_information")
                      }
                      value={company?.royalts}
                      onChange={(value) => handleState("royalts", value)}
                    />
                  </Form.Item>
                </Col>

                <Col sm={5}>
                  <Form.Item
                    label="Marketing"
                    name="marketing"
                    initialValue={0}
                    rules={[
                      { required: false, message: "Marketing é obrigatório" },
                    ]}
                  >
                    <MonetaryInput
                      disabled={
                        !verifyPermission("company.edit_contract_information")
                      }
                      value={company?.marketing}
                      onChange={(value) => handleState("marketing", value)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={4}>
                <Col sm={6}>
                  <Form.Item
                    label="Software"
                    name="software"
                    initialValue={0}
                    rules={[
                      { required: false, message: "Software é obrigatório" },
                    ]}
                  >
                    <MonetaryInput
                      disabled={
                        !verifyPermission("company.edit_contract_information")
                      }
                      value={company?.software}
                      onChange={(value) => handleState("software", value)}
                    />
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <Form.Item
                    label="Inicio do contrato"
                    name="contract_start"
                    rules={[
                      {
                        required: selectedReason === 5 ? false : true,
                        message: "Inicio do contrato é obrigatório",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Inicio contrato"
                      name="contractStart"
                      onChange={(value) => handleState("contract_start", value)}
                      format="DD/MM/YYYY"
                      allowClear={false}
                      inputReadOnly={true}
                      disabled={
                        !verifyPermission("company.edit_contract_information")
                      }
                    />
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <Form.Item
                    label="Tempo de Contrato"
                    name="contract_time"
                    rules={[
                      {
                        required: selectedReason === 5 ? false : true,
                        message: "Tempo de Contrato é obrigatório",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      disabled={
                        !verifyPermission("company.edit_contract_information")
                      }
                      name="contract_time"
                      placeholder="Duração"
                      onChange={({ target: { value } }) =>
                        handleState("contract_time", value)
                      }
                      suffix={"meses"}
                    />
                  </Form.Item>
                </Col>
                <Col sm={5}>
                  <Form.Item label="Grupo Selecionado" name="selected_group">
                    <Select
                      disabled={
                        !verifyPermission("company.edit_contract_information")
                      }
                      placeholder="Grupo Selecionado"
                      onChange={(value) => handleState("selected_group", value)}
                    >
                      <Option value={true}>Sim</Option>
                      <Option value={false}>Não</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={4}>
                <Col sm={6}>
                  <Form.Item
                    label="Loja Própria"
                    name="own_store"
                    rules={[
                      {
                        required: selectedReason === 5 ? false : true,
                        message: "Loja Própria é obrigatório",
                      },
                    ]}
                  >
                    <Select
                      disabled={
                        !verifyPermission("company.edit_contract_information")
                      }
                      placeholder="Loja Própria"
                      onChange={(value) => handleState("own_store", value)}
                    >
                      <Option value={true}>Sim</Option>
                      <Option value={false}>Não</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <Form.Item
                    label="Número do contrato"
                    name="number_contract"
                    rules={[
                      {
                        required: selectedReason === 5 ? false : true,
                        message: "Campo obrigatório",
                      },
                    ]}
                  >
                    <Input
                      name="number_contract"
                      onChange={({ target: { value } }) =>
                        handleState("number_contract", value)
                      }
                    />
                  </Form.Item>
                </Col>

                <Col sm={24}>
                  <Form.Item
                    label={
                      company?.id
                        ? "Contrato da loja"
                        : "Adicione o contrato da loja"
                    }
                    name="contract"
                  >
                    <ContentUploadDiv>
                      {!company?.contract && (
                        <FormUpload noStyle>
                          <Upload
                            typeFile=".pdf"
                            file={contractFile}
                            setFile={setContractFile}
                          />
                        </FormUpload>
                      )}

                      {contractFile && (
                        <div className="delete">
                          <TrashIcon onClick={handleContractRemove} />
                        </div>
                      )}
                      {company?.contract && company?.id && (
                        <DashedContractDiv>
                          <button onClick={() => downloadPdfContractFile()}>
                            Visualizar PDF
                          </button>
                          <TrashIcon onClick={handleContractRemove} />
                        </DashedContractDiv>
                      )}
                    </ContentUploadDiv>
                  </Form.Item>
                </Col>
              </Row>

              <Divider orientation="left">Informações da empresa</Divider>
              <Form.Item
                label="Franqueados vinculados"
                rules={[
                  {
                    required: false,
                    message: "Por favor, preencha os franqueados vinculados",
                  },
                ]}
              >
                {userRows.map((user) => {
                  return (
                    <Row
                      gutter={8}
                      key={user.index}
                      style={{ display: "flex", alignItems: "normal" }}
                    >
                      <Col md={24}>
                        <Space>
                          <Select
                            style={{ width: "20rem", marginBottom: "1rem" }}
                            placeholder="Vincule aqui"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) =>
                              handleUserChange(user.index, "user_id", value)
                            }
                            defaultValue={user.user_id || ""}
                          >
                            {users.map((info) => (
                              <Option key={info.id} value={info.id}>
                                {info.full_name}
                              </Option>
                            ))}
                          </Select>
                          <Input
                            placeholder="Porcentagem (%)"
                            onChange={(e) =>
                              handleUserChange(
                                user.index,
                                "company_percentage",
                                e.target.value
                              )
                            }
                            defaultValue={user.company_percentage || ""}
                            suffix={"%"}
                          />
                          <Select
                            style={{ width: "15rem" }}
                            placeholder="Selecione a função"
                            onChange={(value) =>
                              handleUserChange(user.index, "role", value)
                            }
                            value={user?.role !== null ? user?.role : undefined}
                          >
                            <Option value={0}>Franqueado Operador</Option>
                            <Option value={1}>Franqueado Investidor</Option>
                          </Select>
                        </Space>
                      </Col>
                    </Row>
                  );
                })}
                <Space>
                  {userRows.length > 1 && (
                    <AddOrRemoveButton
                      color={"var(--gray-45)"}
                      onClick={() => handleRemoveRow(userRows.length - 1)}
                      icon="-"
                    >
                      Remover
                    </AddOrRemoveButton>
                  )}
                  <Tooltip title="Adicionar outro franqueado">
                    <AddOrRemoveButton
                      color={"var(--orange-100)"}
                      onClick={handleAddRow}
                      type="primary"
                      icon="+"
                    >
                      Adicionar
                    </AddOrRemoveButton>
                  </Tooltip>
                </Space>
              </Form.Item>
            </>
          )}

          {currentPage === 5 && (
            <div>
              <Divider orientation="left">Endereço</Divider>
              {selectCountryInput === "BR" ? (
                <>
                  <Row gutter={4}>
                    <Col sm={6}>
                      <Form.Item
                        label="CEP"
                        name="cep"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "CEP é obrigatório",
                          },
                        ]}
                      >
                        <MaskedInput
                          placeholder="CEP"
                          mask="11111-111"
                          name="cep"
                          onChange={({ target: { value } }) => {
                            handleState("cep", onlyNumbers(value)?.toString());
                            handleChangeCep(value);
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="Estado"
                        name="state_registration"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Estado é obrigatório",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Estado"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) =>
                            handleState("state_registration", value)
                          }
                        >
                          {brazilStates.map((state) => (
                            <Select.Option value={state} key={state}>
                              {state}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="Cidade"
                        name="city"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Cidade é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="city"
                          placeholder="Cidade"
                          maxLength={70}
                          onChange={({ target: { value } }) =>
                            handleState("city", value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={5}>
                      <Form.Item
                        label="ID Cidade"
                        name="city_id"
                        rules={[
                          {
                            required: false,
                            message: "ID Cidade é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="city_id"
                          placeholder="ID Cidade"
                          onChange={({ target: { value } }) =>
                            handleState("city_id", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={4}>
                    <Col sm={6}>
                      <Form.Item
                        label="Endereço"
                        name="address"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Endereço é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="address"
                          placeholder="Endereço"
                          maxLength={100}
                          onChange={({ target: { value } }) =>
                            handleState("address", value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="Número"
                        name="number"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Número é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="number"
                          placeholder="Nº"
                          onChange={({ target: { value } }) =>
                            handleState("number", value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="Bairro"
                        name="district"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Bairro é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="district"
                          placeholder="Bairro"
                          onChange={({ target: { value } }) =>
                            handleState("district", value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={5}>
                      <Form.Item
                        label="Complemento"
                        name="complement"
                        rules={[
                          {
                            required: false,
                            message: "Complemento é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="complement"
                          placeholder="Distância"
                          maxLength={30}
                          onChange={({ target: { value } }) =>
                            handleState("complement", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={4}>
                    <Col sm={6}>
                      <Form.Item
                        label="Distância até a Fábrica (Km)"
                        name="distance"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Por favor, insira a distância!",
                          },
                          {
                            pattern: /^[0-9]+$/,
                            message:
                              "Apenas números inteiros positivos são permitidos!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          name="distance"
                          placeholder="Distância"
                          onChange={({ target: { value } }) =>
                            handleState("distance", value)
                          }
                          onKeyDown={(e) => {
                            if (
                              e.key === "." ||
                              e.key === "," ||
                              e.key === "+" ||
                              e.key === "-" ||
                              e.key === "e"
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="Pedido Mínimo"
                        name="min_order"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Pedido mínimo obrigatório",
                          },
                        ]}
                      >
                        <Input
                          disabled={
                            !verifyPermission(
                              "company.edit_contract_information"
                            )
                          }
                          type="text"
                          name="min_order"
                          placeholder="Pedido Mínimo"
                          onChange={({ target: { value } }) =>
                            handleState("min_order", value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="População Aproximada"
                        name="population"
                        rules={[
                          {
                            pattern: /^[0-9]+$/,
                            message:
                              "Apenas números inteiros positivos são permitidos!",
                          },
                        ]}
                      >
                        <Input
                          disabled={
                            !verifyPermission(
                              "company.edit_contract_information"
                            )
                          }
                          type="number"
                          name="population"
                          placeholder="População Aproximada"
                          onChange={({ target: { value } }) => {
                            if (value.trim() !== "") {
                              handleState("population", value.trim());
                            }
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === "." ||
                              e.key === "," ||
                              e.key === "+" ||
                              e.key === "-" ||
                              e.key === "e"
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : (
                //---------------------------------------------------------------------- Paraguai
                <>
                  <Row gutter={4}>
                    <Col sm={6}>
                      <Form.Item
                        label="Departamento"
                        name="description_departament_py"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Departamento é obrigatório",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Departamento"
                          showSearch
                          optionFilterProp="children"
                          optionLabelProp="children"
                          loading={loadingDepartments}
                          notFoundContent={
                            loadingDepartments ? <Spin size="small" /> : null
                          }
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={handleDepartmentChange}
                          onFocus={handleDepartmentsFocus}
                        >
                          {departments?.map((department) => (
                            <Select.Option
                              value={department.departament_id}
                              key={department.departament_id}
                            >
                              {department.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="Distrito"
                        name="distrito_py"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Distrito é obrigatório",
                          },
                        ]}
                      >
                        <Select
                          disabled={
                            !selectedDepartment &&
                            !company?.description_departament_py
                          }
                          placeholder="Distrito"
                          showSearch
                          notFoundContent={
                            loadingDistricts ? <Spin size="small" /> : null
                          }
                          loading={loadingDistricts}
                          optionFilterProp="children"
                          optionLabelProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={handleDistrictChange}
                          onFocus={handleDistrictsFocus}
                        >
                          {districts?.map((district) => (
                            <Select.Option
                              value={district.district_id}
                              key={district.district_id}
                            >
                              {district.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="Cidade"
                        name="city"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Selecione a cidade",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecione a cidade"
                          onFocus={handleCityFocus}
                          optionLabelProp="children"
                          optionFilterProp="children"
                          showSearch
                          loading={loadingCities}
                          notFoundContent={
                            loadingCities ? <Spin size="small" /> : null
                          }
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          disabled={!selectedDistrict && !company?.distrito_py}
                          onChange={handleCityChange}
                        >
                          {cities?.map((city) => (
                            <Select.Option
                              key={city.city_id}
                              value={city.city_id}
                            >
                              {city.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <Form.Item
                        label="Endereço"
                        name="address"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Endereço é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="address"
                          placeholder="Endereço"
                          maxLength={100}
                          onChange={({ target: { value } }) =>
                            handleState("address", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={4}>
                    <Col sm={6}>
                      <Form.Item
                        label="Número"
                        name="number"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Número é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="number"
                          placeholder="Nº"
                          onChange={({ target: { value } }) =>
                            handleState("number", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <Form.Item
                        label="Bairro"
                        name="district"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Bairro é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="district"
                          placeholder="Bairro"
                          onChange={({ target: { value } }) =>
                            handleState("district", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <Form.Item
                        label="Complemento"
                        name="complement"
                        rules={[
                          {
                            required: false,
                            message: "Complemento é obrigatório",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="complement"
                          placeholder="Distância"
                          maxLength={30}
                          onChange={({ target: { value } }) =>
                            handleState("complement", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <Form.Item
                        label="Distância até a Fábrica (Km)"
                        name="distance"
                        rules={[
                          {
                            required: true,
                            message: "Por favor, insira a distância!",
                          },
                          {
                            pattern: /^[0-9]+$/,
                            message:
                              "Apenas números inteiros positivos são permitidos!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          name="distance"
                          placeholder="Distância"
                          onChange={({ target: { value } }) =>
                            handleState("distance", value)
                          }
                          onKeyDown={(e) => {
                            if (
                              e.key === "." ||
                              e.key === "," ||
                              e.key === "+" ||
                              e.key === "-" ||
                              e.key === "e"
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={4}>
                    <Col sm={6}>
                      <Form.Item
                        label="Pedido Mínimo"
                        name="min_order"
                        rules={[
                          {
                            required: selectedReason === 5 ? false : true,
                            message: "Pedido mínimo obrigatório",
                          },
                        ]}
                      >
                        <Input
                          disabled={
                            !verifyPermission(
                              "company.edit_contract_information"
                            )
                          }
                          type="text"
                          name="min_order"
                          placeholder="Pedido Mínimo"
                          onChange={({ target: { value } }) =>
                            handleState("min_order", value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={6}>
                      <Form.Item
                        label="População Aproximada"
                        name="population"
                        rules={[
                          {
                            pattern: /^[0-9]+$/,
                            message:
                              "Apenas números inteiros positivos são permitidos!",
                          },
                        ]}
                      >
                        <Input
                          disabled={
                            !verifyPermission(
                              "company.edit_contract_information"
                            )
                          }
                          type="number"
                          name="population"
                          placeholder="População Aproximada"
                          onChange={({ target: { value } }) =>
                            handleState("population", value)
                          }
                          onKeyDown={(e) => {
                            if (
                              e.key === "." ||
                              e.key === "," ||
                              e.key === "+" ||
                              e.key === "-" ||
                              e.key === "e"
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          )}
          {currentPage === 6 && (
            <div>
              <Divider orientation="left">Tributações (Paraguai)</Divider>
              <Row gutter={4}>
                <Col sm={6}>
                  <Form.Item label="Número Timbrado" name="number_timbrado_py">
                    <Input
                      type="text"
                      name="number_timbrado_py"
                      placeholder="Número Timbrado"
                      onChange={({ target: { value } }) =>
                        handleState("number_timbrado_py", value)
                      }
                    />
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <Form.Item
                    label="Código Estabelecimento"
                    name="code_establishment_py"
                  >
                    <Input
                      type="text"
                      name="code_establishment_py"
                      placeholder="Código Estabelecimento"
                      onChange={({ target: { value } }) =>
                        handleState("code_establishment_py", value)
                      }
                    />
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <Form.Item
                    label="Código Ponto de Expedição"
                    name="code_shipping_point_py"
                  >
                    <Input
                      type="text"
                      name="code_shipping_point_py"
                      placeholder="Código Ponto de Expedição"
                      onChange={({ target: { value } }) =>
                        handleState("code_shipping_point_py", value)
                      }
                    />
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <Form.Item
                    label="Data Inicial Timbrado"
                    name="initial_date_timbrado_py"
                  >
                    <DatePicker
                      placeholder="Data Inicial Timbrado"
                      format="DD/MM/YYYY"
                      onChange={(date, dateString) =>
                        handleState("initial_date_timbrado_py", date)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={4}>
                <Col sm={6}>
                  <Form.Item
                    label="Código Integração"
                    name="code_integration_py"
                  >
                    <Input
                      type="text"
                      name="code_integration_py"
                      placeholder="Código Integração"
                      onChange={({ target: { value } }) =>
                        handleState("code_integration_py", value)
                      }
                    />
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <Form.Item label="Chave Secreta" name="secret_key_py">
                    <Input
                      type="text"
                      name="secret_key_py"
                      placeholder="Chave Secreta"
                      onChange={({ target: { value } }) =>
                        handleState("secret_key_py", value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default CompanyForm;
