import React, { useState, useEffect } from "react";
import moment from "moment";

import {
  Container,
  TopContainer,
  Content,
  Select,
  Spin,
  CentralizeContent,
  TopContent,
  MidContent,
  Table,
  Menu,
  Dropdown,
  ThreeDotsIcon,
  Button,
  Modal,
  AutoComplete,
  ModalContent,
} from "./styles";
import { notification, Empty } from "antd";
import { ColumnsType } from "antd/lib/table";

import PageContainer from "../../containers/PageContainer";

import { getTokenInfo, verifyPermission } from "../../services/auth";
import api from "../../services/api";

type File = {
  id: number;
  owner: string;
  folder: string;
  file_mime_type: string;
  file_name: string;
  file_url: string;
  user_id: number;
  user_name: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: null;
};

const FileManagement: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);

  const [stores, setStores] = useState<{ id: number | string; name: string }[]>(
    []
  );
  const [selectedOwner, setSelectedOwner] = useState<string | null>(null);
  const [fetchingStores, setFetchingStores] = useState(true);

  const [files, setFiles] = useState<File[]>([]);
  const [folders, setFolders] = useState<string[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [shouldFetchFiles, setShouldFetchFiles] = useState(true);

  const formatAndEmitErrorMessages = (error: any) => {
    const message =
      error.response?.message ||
      error.response?.data?.message ||
      error?.response?.erros ||
      error.response?.mensagem;
    notification.warning({ message });
  };

  useEffect(() => {
    const fetchStores = async () => {
      try {
        setFetchingStores(true);
        const user = getTokenInfo();
        const {
          data: { content },
        } = await api.get(`/companyUser/${user?.id}/user`);
        const response = content.map((company) => ({
          id: company.company_id,
          name: company.company.company_name,
        }));
        setStores(response);
      } catch (error) {
        formatAndEmitErrorMessages(error);
      } finally {
        setFetchingStores(false);
      }
    };
    fetchStores();
    setStores([]);
  }, []);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        setFetchingFiles(true);

        const {
          data: { content },
        } = await api.get(`/files-management/owner/${selectedOwner}`);

        let _folders = content.map((_file) => _file.folder);
        _folders = [...new Set(_folders)];

        setFiles(content);
        setFolders(_folders);
      } catch (error) {
        formatAndEmitErrorMessages(error);
      } finally {
        setFetchingFiles(false);
        setShouldFetchFiles(false);
        setSelectedFolder(null);
      }
    };
    if (selectedOwner && shouldFetchFiles) fetchFiles();
  }, [selectedOwner, shouldFetchFiles]);

  const removeFile = async (file: File) => {
    Modal.confirm({
      title: "Tem certeza de que remover este arquivo?",
      okText: "Sim",
      centered: true,
      okType: "primary",
      cancelText: "Não",
      width: 500,
      async onOk() {
        try {
          setFetchingFiles(true);
          await api.delete(`/files-management/${file.id}`);
          notification.success({
            message: `Arquivo ${file.file_name} removido com sucesso`,
          });
          setShouldFetchFiles(true);
        } catch (error) {
          formatAndEmitErrorMessages(error);
        } finally {
          setFetchingFiles(false);
        }
      },
    });
  };

  const copyLink = async (type: "download" | "beautyfy", file: File) => {
    if (type === "download") {
      await navigator.clipboard.writeText(file.file_url);
    } else {
      await navigator.clipboard.writeText(
        `${process.env.REACT_APP_API_URL}/files-management/${file.owner}/${file.folder}/${file.file_name}/beautify`
      );
    }
    notification.success({ message: "Link copiado com sucesso" });
  };

  const addFile = async (folderName: string, fileToAdd) => {
    try {
      setFetchingFiles(true);

      const formData = new FormData();

      formData.append("file", fileToAdd);
      formData.append("owner", selectedOwner as string);
      formData.append("folder", folderName as string);
      formData.append("action", "create");

      await api.post("/files-management", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      notification.success({
        message: "Arquivo criado com sucesso",
      });
      setShouldFetchFiles(true);
    } catch (error) {
      formatAndEmitErrorMessages(error);
    } finally {
      setFetchingFiles(false);
    }
  };

  const handleFilterFiles = (
    _selectedFolder: string | null,
    _files: File[]
  ) => {
    if (!_selectedFolder) return _files;
    return _files.filter((_file) => _file.folder === _selectedFolder);
  };

  const menu = (row) => {
    return (
      <>
        <Menu>
          <Menu.Item key="0" onClick={() => console.log(row)}>
            <a href={row.file_url} target="_blank">
              Baixar
            </a>
          </Menu.Item>
          <Menu.Item key="1" onClick={() => copyLink("download", row)}>
            Copiar link download
          </Menu.Item>
          <Menu.Item key="2" onClick={() => console.log(row)}>
            <a
              href={`${process.env.REACT_APP_API_URL}/files-management/${row.owner}/${row.folder}/${row.file_name}/beautify`}
              target="_blank"
            >
              Visualizar
            </a>
          </Menu.Item>
          <Menu.Item key="3" onClick={() => copyLink("beautyfy", row)}>
            Copiar link visualização
          </Menu.Item>

          {verifyPermission("file_management.remove_file") ? (
            <Menu.Item key="4" onClick={() => removeFile(row)}>
              Remover
            </Menu.Item>
          ) : (
            <></>
          )}
        </Menu>
      </>
    );
  };

  const columns: ColumnsType = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Arquivo",
      dataIndex: "file_name",
      align: "center",
    },
    {
      title: "Tipo Arquivo",
      dataIndex: "file_mime_type",
      align: "center",
    },
    {
      title: "Pasta",
      dataIndex: "folder",
      align: "center",
    },
    {
      title: "Criado Por",
      dataIndex: "user_name",
      align: "center",
    },
    {
      title: "Data",
      dataIndex: "created_at",
      align: "center",
      render: (value) => (
        <span>{moment(value).format("DD/MM/YYYY HH:mm:ss")}</span>
      ),
    },
    {
      title: "Ações",
      dataIndex: "actions",
      align: "center",
      render: (_, row) => (
        <Dropdown overlay={menu(row)}>
          <ThreeDotsIcon />
        </Dropdown>
      ),
    },
  ];

  const handleStores = (_stores: { id: number | string; name: string }[]) => {
    let response = [..._stores];

    const hasPermissionToTiFiles = verifyPermission("file_management.ti_file");
    if (hasPermissionToTiFiles) {
      response = [{ id: "ti", name: "ti" }, ..._stores];
    }
    return response;
  };

  return (
    <>
      <PageContainer route="Gerenciador de Arquivos">
        <Container>
          <TopContainer>
            <Select
              disabled={fetchingStores}
              placeholder="Selecione uma loja"
              onChange={(id) => setSelectedOwner(id?.toString() || null)}
              loading={fetchingStores}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {handleStores(stores).map((store) => (
                <Select.Option
                  key={store.id}
                  value={store.id}
                  style={{ textTransform: "uppercase" }}
                >
                  {store.name}
                </Select.Option>
              ))}
            </Select>
            {selectedOwner && verifyPermission("file_management.add_file") ? (
              <Button onClick={() => setOpenModal(true)}>Novo Arquivo</Button>
            ) : (
              <></>
            )}
          </TopContainer>
          <Content>
            {fetchingFiles ? (
              <>
                <CentralizeContent>
                  <Spin />
                </CentralizeContent>
              </>
            ) : (
              <>
                {files.length ? (
                  <>
                    <TopContent>
                      <Select
                        showSearch
                        allowClear
                        onClear={() => setSelectedFolder(null)}
                        value={selectedFolder || undefined}
                        placeholder="Selecione a pasta"
                        onChange={(_folder) =>
                          setSelectedFolder(_folder as string)
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {folders.map((folder) => (
                          <Select.Option
                            key={folder}
                            value={folder}
                            style={{ textTransform: "uppercase" }}
                          >
                            {folder}
                          </Select.Option>
                        ))}
                      </Select>
                    </TopContent>
                    <MidContent>
                      <Table
                        size="small"
                        pagination={{
                          showSizeChanger: false,
                          pageSize: 50,
                          total: files.length,
                        }}
                        columns={columns as any}
                        dataSource={handleFilterFiles(
                          selectedFolder,
                          files
                        ).map((file) => ({
                          ...file,
                          key: file.id,
                        }))}
                      />
                    </MidContent>
                  </>
                ) : (
                  <CentralizeContent>
                    <Empty description="Nenhum arquivo encontrado" />
                  </CentralizeContent>
                )}
              </>
            )}
          </Content>
        </Container>
      </PageContainer>
      <Modal
        destroyOnClose
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        cancelText="Fechar"
        okText="Salvar"
        onOk={() => {
          const inputFolderFile = document.getElementById("input_folder_file");
          const inputFile = document.getElementById("input_file");
          //@ts-ignore
          const file = inputFile.files[0];
          //@ts-ignore
          const folder = inputFolderFile.value;
          if (!file || !folder) {
            return notification.warning({
              message: "Preencha todas as informações corretamente",
            });
          }
          addFile(folder, file);
          setOpenModal(false);
        }}
      >
        <ModalContent>
          <AutoComplete
            options={folders.map((folder) => ({ value: folder }))}
            onSelect={(text) => console.log(text)}
            onSearch={(text) => console.log(text)}
            placeholder="Pasta"
            id="input_folder_file"
          />
          <input type="file" id="input_file" />
        </ModalContent>
      </Modal>
    </>
  );
};

export default FileManagement;
