import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { getTokenInfo } from "../../services/auth";
import { logout } from "../../services/auth";
import { verifyPermission } from "../../services/auth";

import LogoImg from "../../assets/image/logo.png";
import AvatarUser from "../../assets/image/avatar_user.png";

import {
  Container,
  LogoContainer,
  NavMenuContainer,
  NavItem,
  MenuContainer,
  Footer,
  UserInfoContainer,
  ButtonLogout,
  LogOutIcon,
  AccessIcon,
  CompaniesIcon,
  HomeIcon,
  ModuleIcon,
  UsersIcon,
  MenuIcon,
  CloseIcon,
  FileManagmenteIcon,
} from "./styles";
import { Modal } from "antd";

interface IProps extends RouteComponentProps<any> {}

const SideMenu: React.FC<IProps> = ({ history }) => {
  const [currentRoute, setCurrentRoute] = useState<string | null>(null);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const formatPerfil = (perfil?: number) => {
    if (perfil === 1) {
      return "Master";
    }
    if (perfil === 2) {
      return "Full";
    }
    if (perfil === 3) {
      return "Admin";
    }
    if (perfil === 4) {
      return "Proprietário";
    }

    return "Usuário";
  };

  const redirect = () => {
    const link = document.createElement("a");
    link.href = process.env.REACT_APP_WEB_AUTH_URL as string;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    setCurrentRoute(history.location.pathname);
  }, [history.location.pathname]);

  const isSamePath = (_path: string): boolean => {
    return _path === currentRoute;
  };

  const logOutAut = () => {
    Modal.confirm({
      title: "Tem certeza de que deseja sair?",
      okText: "Sim",
      centered: true,
      okType: "primary",
      cancelText: "Não",
      width: 500,
      async onOk() {
        logout();
        redirect();
      },
    });
  };

  const menus = [
    {
      id: 1,
      label: "Página Inicial",
      path: "/",
      icon: <HomeIcon />,
      permission: null,
    },
    {
      id: 2,
      label: "Usuários",
      path: "/users",
      icon: <UsersIcon />,
      permission: "user.access_permission",
    },
    {
      id: 3,
      label: "Empresas",
      path: "/companies",
      icon: <CompaniesIcon />,
      permission: "company.access_permission",
    },
    {
      id: 4,
      label: "Módulos",
      path: "/modules",
      icon: <ModuleIcon />,
      permission: "module.access_permission",
    },
    {
      id: 5,
      label: "Opções de acesso",
      path: "/access-permission",
      icon: <AccessIcon />,
      permission: "access.access_permission",
    },
    {
      id: 5,
      label: "Gerenciador de Arquivos",
      path: "/files-management",
      icon: <FileManagmenteIcon />,
      permission: "file_management.access_permission",
    },
  ];

  return (
    <>
      {isVisible ? (
        <CloseIcon onClick={() => setIsVisible(false)} />
      ) : (
        <MenuIcon onClick={() => setIsVisible(true)} />
      )}
      <Container responsive={isVisible}>
        <span className="logout-mobile" onClick={() => logOutAut()}>
          Sair
        </span>
        <LogoContainer>
          <img
            src={LogoImg}
            alt="Logo circular cor preta com bordas brancas escrito Amadelli The Best Açaí"
          />
        </LogoContainer>

        <NavMenuContainer>
          {menus.map((menu) => (
            <>
              {(!menu.permission || verifyPermission(menu.permission)) && (
                <MenuContainer
                  key={menu.id}
                  onClick={() => setIsVisible(false)}
                >
                  <NavItem to={menu.path} actived={isSamePath(menu.path)}>
                    {menu.icon}
                    <span>{menu.label}</span>
                  </NavItem>
                </MenuContainer>
              )}
            </>
          ))}
        </NavMenuContainer>

        <Footer>
          <span className="perfil">PERFIL</span>
          <UserInfoContainer>
            <img
              src={getTokenInfo()?.image ? getTokenInfo()?.image : AvatarUser}
              alt="Imagem de avatar do usuário"
            />

            <div className="info-user">
              <span className="name-user">{getTokenInfo()?.name}</span>
              <span className="role-user">
                {formatPerfil(getTokenInfo()?.level)}
              </span>
            </div>
          </UserInfoContainer>

          <ButtonLogout onClick={() => logOutAut()}>
            <LogOutIcon />
            <span>Sair</span>
          </ButtonLogout>
        </Footer>
      </Container>
    </>
  );
};

export default withRouter(SideMenu);
