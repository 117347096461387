import React, { useState, useEffect } from "react";
import AccessPermission from "../pages/AccessPermission";
import Home from "../pages/Home";
import Modules from "../pages/Modules";
import User from "../pages/User";
import Company from "../pages/Company";
import Login from "../pages/Login";
import ModulePermission from "../pages/ModulePermission";
import FileManagement from "../pages/FileManagement";
import Layout from "../containers/Layout";
import { verifyPermission, isAuthenticated } from "../services/auth";

import { Switch, Route, RouteProps, Redirect } from "react-router-dom";

interface PrivateRouteProps extends RouteProps {
  component: any;
  roles?: number[];
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, roles, ...rest } = props;
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const fetchPermissionWithRole = async () => {
      const response = await verifyPermission(roles);
      setAuthenticated(response);
      setLoading(false);
    };
    const fetchPermissionWithOutRole = async () => {
      const response = await isAuthenticated();
      setAuthenticated(response);
      setLoading(false);
    };
    if (roles) {
      fetchPermissionWithRole();
    } else {
      fetchPermissionWithOutRole();
    }
  }, [roles]);
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        loading ? (
          <></>
        ) : authenticated ? (
          <Layout>
            <Component {...routeProps} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/access-permission"
        component={AccessPermission}
      />
      <PrivateRoute exact path="/modules" component={Modules} />
      <PrivateRoute exact path="/users" component={User} />
      <PrivateRoute exact path="/companies" component={Company} />

      <PrivateRoute
        exact
        path="/module-permission"
        component={ModulePermission}
      />

      <PrivateRoute exact path="/files-management" component={FileManagement} />

      <Route exact path="/login" component={Login} />

      <PrivateRoute path="*" component={Home} />
    </Switch>
  );
};

export default Routes;
