import React, { useEffect, useState } from "react";

import {
  decodeToken,
  getModules,
  getTokenInfo,
  getUserCountries,
} from "../../services/auth";
import PageContainer from "../../containers/PageContainer";
import CompanyWarning from "../../containers/CompanyWarning";

import ModuleCard from "../../components/ModuleCard";

import { Container, BannerContainer, CardContainer, Cards } from "./styles";

type Modules = {
  id: number;
  image: string;
  name: string;
  description: string;
  url: string;
};

const Home: React.FC = () => {
  const [modulesList, setModulesList] = useState<Modules[]>([]);
  const [userMagicLing, setUserMagicLink] = useState<string>();

  useEffect(() => {
    const init = () => {
      const user = getTokenInfo();
      const _modules = getModules();
      const countries = getUserCountries();

      setUserMagicLink(user?.magic_link_twygo);

      if (
        countries?.some((country) => country === "paraguai") ||
        (user && user?.level <= 1)
      ) {
        if (
          countries?.some((country) => country === "brasil") ||
          (user && user?.level <= 1)
        ) {
          setModulesList(_modules);
        } else {
          const _gestorBrasilModuleRemove = _modules.filter(
            (_module) => _module.name !== "Gestor de Vendas"
          );
          setModulesList(_gestorBrasilModuleRemove);
        }
      } else {
        const _gestorParaguaiModuleRemove = _modules.filter(
          (_module) => _module.name !== "Gestor de Vendas - Paraguai"
        );
        setModulesList(_gestorParaguaiModuleRemove);
      }
    };

    init();
  }, []);

  const userMagicLingPath = userMagicLing
    ? userMagicLing.split("universidade.franqueadosthebest.com")[1]
    : "";

  return (
    <PageContainer route="Página Inicial">
      <Container>
        <BannerContainer>
          <h1>
            Sistemas <br /> The Best Açaí
          </h1>
          <p>
            Olá <span>{decodeToken().name}</span>, o que vamos fazer de bom
            hoje?
          </p>
        </BannerContainer>

        <CardContainer>
          <Cards>
            {modulesList.map((modules) => (
              <ModuleCard
                _module={modules}
                key={modules.id}
                url_twygo={userMagicLingPath}
              />
            ))}
          </Cards>
        </CardContainer>
      </Container>
      <CompanyWarning />
    </PageContainer>
  );
};

export default Home;
