import decode from "jwt-decode";
import jwt from "jsonwebtoken";

export const TOKEN_KEY = "access-token";
export const PERMISSIONS_KEY = "permissions";
export const MODULES_KEY = "modules";
export const COUNTRIES = "countries";

export const TOKEN_SECRET_KEY = process.env.REACT_APP_ACCESS_TOKEN_SECRET || "";

type User = {
  id: number;
  name: string;
  email: string;
  level: number;
  image: string;
  franchisee: number;
  magic_link_twygo: string;
};

type Modules = {
  id: number;
  image: string;
  name: string;
  description: string;
  url: string;
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getPermission = () => localStorage.getItem(PERMISSIONS_KEY);
export const getCountries = () => localStorage.getItem(COUNTRIES);

export const getModules = (): Modules[] => {
  const modules = localStorage.getItem(MODULES_KEY);

  if (modules === null) {
    return [];
  }

  return JSON.parse(modules).filter(
    (module) => !module.name.includes("Autenticador")
  );
};

export const getTokenInfo = (): User | null => {
  const accessToken = getToken();
  if (accessToken === null) {
    return null;
  }
  try {
    let result = jwt.verify(accessToken, TOKEN_SECRET_KEY) as User;
    return result;
  } catch (err) {
    return null;
  }
};

export const verifyToken = (): boolean => {
  const accessToken = getToken();
  if (accessToken === null) {
    return false;
  }
  try {
    jwt.verify(accessToken, TOKEN_SECRET_KEY);
    return true;
  } catch (err) {
    return false;
  }
};

export const decodeToken = (): User => {
  const accessToken = getToken();

  const user = decode<any>(accessToken as string);

  return user;
};

export const verifyPermission = (permission): boolean => {
  let _permissions = getPermission();

  if (_permissions === null) {
    return false;
  }

  const permissions = JSON.parse(_permissions) as String[];

  return permissions.some((_permission) => _permission === permission);
};

export const getUserCountries = () => {
  const _countries = getCountries();
  if (!_countries) {
    return [];
  }
  if (_countries === "undefined") {
    return [];
  }
  const countries = JSON.parse(_countries) as String[];

  return countries;
};

export const isAuthenticated = async () => {
  const token = getToken();
  if (token === null) {
    return false;
  }
  const isValid = await verifyToken();
  if (!isValid) {
    return false;
  }
  return true;
};

export const login = (
  token: string,
  modules: string,
  permissions: string,
  countries: string
) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(PERMISSIONS_KEY, permissions);
  localStorage.setItem(MODULES_KEY, modules);
  localStorage.setItem(COUNTRIES, countries);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
